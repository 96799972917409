import React from "react";
import Drawer from "react-modern-drawer";
import { useDrawerContext } from "../../context/drawer-context";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";
import { Separator } from "../index";

const StyledLink = styled.li`
  padding: 1rem 0;
  position: relative;
  display: block;

  a {
    font-size: 14px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s;

    &.small {
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
    }

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export function DrawerWrapper({ navData }) {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  return (
    <Drawer
      open={isOpened}
      onClose={toggleIsOpened}
      size={320}
      direction="right"
      className="bla bla bla"
    >
      <div style={{ paddingTop: "4rem" }}>
        <nav>
          <ul style={{ paddingLeft: "1.6rem" }}>
            {navData.map((nav) => {
              return (
                <StyledLink key={nav.id}>
                  <a href={nav.url}>{nav.label}</a>
                </StyledLink>
              );
            })}
          </ul>
          <Separator />
          <ul style={{ paddingLeft: "1.6rem" }}>
            <StyledLink>
              <a
                target="_blank"
                href="https://www.homeserve.fr/"
                rel="noreferrer"
                className="small"
              >
                HomeServe.fr
              </a>
            </StyledLink>
            <StyledLink>
              <a
                href="https://depannage.homeserve.fr/qui-sommes-nous"
                className="small"
              >
                Qui sommes-nous ?
              </a>
            </StyledLink>
            <StyledLink>
              <a href="https://depannage.homeserve.fr/aide" className="small">
                Aide
              </a>
            </StyledLink>
          </ul>
        </nav>
      </div>
    </Drawer>
  );
}
