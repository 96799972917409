module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "HomeServe Dépannage", // Navigation and Site Title
  titleAlt: "HomeServe Dépannage", // Title for JSONLD
  description:
    "HomeServe Dépannage est notre offre de dépannage sans abonnement. Le plus grand réseau de professionnels de France : plombiers, électriciens et chauffagistes. Plus de 124000 dépannages chaque année. 1 million de clients. Depuis 15 ans.",
  headline: "votre reparation...", // Headline for schema.org JSONLD
  url: "https://depannage.homeserve.fr", // Domain of your site. No trailing slash!
  siteLanguage: "fr", // Language Tag on <html> element
  logo: "/logos/logo-1024.png", // Used for SEO
  ogLanguage: "fr_FR", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/assets/images/icon.png", // Used for manifest favicon generation
  shortName: "Homeserve Dépannage", // shortname for manifest. MUST be shorter than 12 characters
  author: "Homeserve Dépannage", // Author for schemaORGJSONLD
  themeColor: "#34e2d9",
  backgroundColor: "#EBEDF2",

  twitter: "@HomeServe", // Twitter Username
  facebook: "HomeServe", // Facebook Site Name
  //googleAnalyticsID: 'UA-0000000-0',
};
