import React from "react";
import styled from "styled-components";
import { Wrapper } from "../index";
import Check from "../../assets/svg/check.svg";
import breakpoint from "../../theme/breakpoints";

const StyledListCheck = styled.div`
  width: 100%;

  margin: 2rem 0;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${(props) => props.theme.colors.customGray};
  border-radius: 7px;
  box-sizing: border-box;
`;

const StyledListCheckItem = styled.div`
  width: 30%;
  margin: 0;
  display: inline-flex;
  line-height: 24px;

  @media only screen and ${breakpoint.device.xs} {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media only screen and ${breakpoint.device.sm} {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const checkData = [
  {
    id: 1,
    text: "<strong>Prix affiché, prix appliqué</strong> <br /> Aucune mauvaise surprise",
  },
  {
    id: 2,
    text: "<strong>Annulation sans frais</strong> <br /> jusqu’à 2 heures avant l’arrivée du pro",
  },
  {
    id: 3,
    text: "<strong>Vous payez en ligne après votre intervention !</strong> <br />Aucun frais à avancer.",
  },
];

export function ListCheck() {
  return (
    <Wrapper>
      <StyledListCheck>
        {checkData.map((item) => {
          return (
            <StyledListCheckItem key={item.id}>
              <Check style={{ marginRight: "1.6rem" }} />
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </StyledListCheckItem>
          );
        })}
      </StyledListCheck>
    </Wrapper>
  );
}
