import React, { useContext, useState } from "react";
import Sticky from "react-stickynode";
import styled from "styled-components";
import { Wrapper } from "../index";
import Logo from "../../assets/svg/logo.svg";
import { Link } from "gatsby";
import Select from "react-select";
import { AppContext } from "../../context/app-context";
import ArrowLink from "../../assets/svg/arrow_link.svg";
import useResponsive from "../../hooks/use-responsive";
import breakpoint from "../../theme/breakpoints";
import PhoneS from "../../assets/svg/phone.svg";
import * as website from "../../../config/website";

const StyledSticky = styled(Sticky)`
  .sticky-inner-wrapper {
    z-index: 99;
    padding: 1rem 0;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    @media only screen and ${breakpoint.device.sm} {
      background-color: ${(props) => props.theme.colors.secondary} !important;
      bottom: 0 !important;
      top: calc(100vh - 83px) !important;
    }

    @media only screen and ${breakpoint.device.xs} {
      background-color: ${(props) => props.theme.colors.secondary} !important;
      bottom: 0 !important;
      top: calc(100vh - 83px) !important;
    }
  }
  &.sticky-outer-wrapper {
    height: 0;
    overflow: hidden;
    opacity: 0;

    &.active {
      opacity: 1;
      background-color: white;
    }
  }
`;

const StyledLink = styled.a`
  width: 100%;
  max-width: 224px;
  height: 100%;
  display: block;
  margin: 0;
  padding: 0.8rem 0;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  color: black;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;

  svg {
    vertical-align: middle;
    fill: ${(props) => props.theme.colors.hsRed};
    margin-left: 0.6rem;
  }

  span {
    font-weight: bold;
  }

  &.red {
    padding: 1rem 1rem;
    background-color: ${(props) => props.theme.colors.hsRed};
    color: white;
    font-size: 1rem;

    svg {
      fill: white;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.darkenHsRed};
    }
  }
  &.disabled {
    padding: 1rem 1rem;
    background-color: ${(props) => props.theme.colors.customGrayLight};
    color: black;
    font-size: 1rem;
    cursor: default;
  }
`;
const StyledArrow = styled(ArrowLink)`
  width: 24px;
  height: 17px;
  color: white;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: 400,
    maxWidth: "100%",
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    background: "white",
  }),

  valueContainer: () => ({
    padding: "0.9rem 0 0.6rem 0.8rem",
    height: "26px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#16445f",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#34e2d9",
  }),
};

export function StickyBar({ options, defaultValue }) {
  const { isBigScreen } = useResponsive();
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const { state } = useContext(AppContext);
  const [phoneNumber] = useState(state.phoneNumber);
  const url = phoneNumber.replace(/ /g, "");
  return (
    <>
      {isBigScreen && (
        <StyledSticky enabled={true} top="#hero" bottomBoundary="#footer">
          <StyledWrapper>
            <a href={website.url}>
              <Logo />
            </a>
            <div style={{ width: "380px" }}>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
                isSearchable={false}
                placeholder="Plomberie, Électricité, Gaz... ?"
                styles={customStyles}
              />
            </div>
            <div style={{ width: "224px" }}>
              {selectedOption === "" ? (
                <StyledLink className="disabled" href="/">
                  {/*<ArrowLink />*/}
                  C'est parti!
                </StyledLink>
              ) : (
                <StyledLink
                  className="red"
                  href={`/autodiagnostic/${selectedOption.value}`}
                >
                  <StyledArrow />
                  C'est parti !
                </StyledLink>
              )}
            </div>
            <div style={{ width: "240px", marginTop: "6px" }}>
              <StyledLink
                href={`tel:${url}`}
                style={{
                  textAlign: "left",
                  padding: "0.6rem 0",
                  display: "contents",
                }}
              >
                <div style={{ float: "left", marginRight: "1rem" }}>
                  <PhoneS />
                </div>
                <span>{phoneNumber}</span>
                <br />{" "}
                <span style={{ fontSize: "0.8rem", fontWeight: "normal" }}>
                  Service gratuit + coût de l'appel
                </span>
              </StyledLink>
            </div>
          </StyledWrapper>
        </StyledSticky>
      )}
      {!isBigScreen && (
        <StyledSticky enabled={true} top="#hero" bottomBoundary="#footer">
          <StyledWrapper>
            <div style={{ width: "220px", marginRight: "1rem" }}>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
                isSearchable={false}
                placeholder="Plomberie ?"
                styles={customStyles}
                menuPlacement="top"
              />
            </div>
            <div style={{ width: "180px" }}>
              {selectedOption === "" ? (
                <StyledLink className="disabled" href="/">
                  {/*<ArrowLink />*/}
                  C'est parti!
                </StyledLink>
              ) : (
                <StyledLink
                  className="red"
                  href={`/autodiagnostic/${selectedOption.value}`}
                >
                  {/*<StyledArrow />*/}
                  C'est parti !
                </StyledLink>
              )}
            </div>
          </StyledWrapper>
        </StyledSticky>
      )}
    </>
  );
}
