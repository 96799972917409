import React from "react";
import styled from "styled-components";

const StyledTitle = styled.div`
  color: black;
`;

export function Title({ children }) {
  return <StyledTitle>{children}</StyledTitle>;
}
