import React from "react";

const Phone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.022"
    height="29.144"
    viewBox="0 0 20.022 29.144"
  >
    <g>
      <path
        fillRule="evenodd"
        d="M2.7 0h10.6A2.711 2.711 0 0 1 16 2.7v21.516a2.722 2.722 0 0 1-2.7 2.757H2.7A2.758 2.758 0 0 1 0 24.216V2.7A2.746 2.746 0 0 1 2.7 0zM6 2.27a.349.349 0 0 1-.324-.324.3.3 0 0 1 .324-.27h4c.216 0 .324.108.324.27A.312.312 0 0 1 10 2.27zm2 22.054a.67.67 0 0 1 .649.7.649.649 0 0 1-1.3 0 .67.67 0 0 1 .651-.7zM1.351 3.892h13.3v19.189h-13.3z"
        transform="translate(4.219) rotate(9) translate(0 0)"
      />
    </g>
  </svg>
);

export default Phone;
