/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../../context/app-context";
import styled from "styled-components";
import { Wrapper, Title } from "../../";

const StyledItem = styled.div`
  width: 260px;
  flex-shrink: 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-bottom: 20px;
  text-align: center;
  scroll-snap-align: start;
  flex-direction: column;
`;

const StyledTitle = styled.h3`
  font-size: 16px;
  margin: 16px;
  padding: 16px
  min-height: 60px;
  min-height: 90px;
  display: flex;
  justify-content: center;
`;

const StyledContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
  object-position: 50% 0%;
`;

const StyledButton = styled.button`
  font-size: 16px;
  background-color: #34e2d9;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: auto;
  width: 90%;
  font-family: MuseoSansRounded-300, Helvetica, Arial, sans-serif;
  :hover {
    background-color: #5de8e1;
  }
`;

export function ProductList({ name }) {
  const [productList, setProductList] = useState(null);
  const { state, dispatch } = useContext(AppContext);
  const urlProduct = "https://depannage.homeserve.fr/produit/";

  async function getToken() {
    try {
      const tenantUrl =
        "https://api.homeserve.fr/ondemand/tenant/authenticateApp/hsv";
      const token = await axios.get(`${tenantUrl}`);
      if (token.status === 200) {
        await dispatch({
          type: "set_app_context",
          payload: { token: token.data },
        });
        await getTopList(token.data.access_token);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  }

  async function getTopList(token) {
    try {
      const catalogUrl = `https://api.homeserve.fr/ondemand/catalog/productLists/${name}`;
      const { data } = await axios.get(`${catalogUrl}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setProductList(data);
    } catch (error) {
      await getToken();
      console.error("Error fetching product list:", error);
    }
  }

  useEffect(async () => {
    if (state.token.access_token === "") {
      await getToken();
    } else await getTopList(state.token.access_token);
  }, []);

  return (
    <Wrapper>
      <div style={{ margin: "3rem 0 2rem 0" }}>
        {productList ? (
          <div>
            <Title>
              <h2>Nos tops dépannages</h2>
            </Title>
            <StyledContainer>
              {productList.products.map((product, index) => (
                <StyledItem key={index}>
                  <StyledImg src={product.picture} alt={index} />
                  <StyledTitle>{product.name}</StyledTitle>
                  <StyledButton
                    onClick={() =>
                      (window.location.href = `${urlProduct}${product.slug}`)
                    }
                  >
                    À partir de <b>{product.customerBasePurchasePrice}€</b>
                  </StyledButton>
                </StyledItem>
              ))}
            </StyledContainer>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Wrapper>
  );
}
