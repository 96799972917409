const size = {
  xs: "320px",
  sm: "768px",
  lg: "1020px",
};
const device = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  lg: `(max-width: ${size.lg})`,
};
export default { size, device };
