import React from "react";
import styled from "styled-components";
import { Wrapper } from "../index";

const StyledSeparator = styled.div`
  width: 100px;
  height: 0;
  border-bottom: solid 4px ${(props) => props.theme.colors.secondary};
`;

export function Separator() {
  return (
    <Wrapper>
      <StyledSeparator />
    </Wrapper>
  );
}
