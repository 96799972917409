import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";

export function MetaModulo({ cityName, modulo }) {
    const [pageUrl, setPageUrl] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setPageUrl(window.location.href);
        }
    }, []);
    let meta = null;
    switch (modulo) {
        case 0:
            meta = (
                <Helmet>
                    <title>
                        Petits travaux et dépannages à domicile à {cityName} - MesBonsPros
                    </title>
                    <meta
                        name="description"
                        content={`Vous vivez à ${cityName} et avez un problème de plomberie, électricité, serrurerie ou électroménager ? Faites confiance à MesBonsPros par HomeServe pour un dépannage à domicile à ${cityName} dans les meilleurs délais.`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
            break;
        case 1:
            meta = (
                <Helmet>
                    <title>Dépannages et petits travaux à domicile à {cityName} - MesBonsPros</title>
                    <meta
                        name="description"
                        content={`Vous avez un problème de fuite d'eau, de panne de courant ou d'appareil électroménager à ${cityName} ? Avec MesBonsPros par HomeServe trouvez un plombier, un électricien ... à ${cityName} en tout simplicité !`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
            break;
        case 2:
            meta = (
                <Helmet>
                    <title>Dépannages d'urgence et petits travaux à {cityName} - MesBonsPros</title>
                    <meta
                        name="description"
                        content={`Besoin de petits travaux ou d'un dépannage en urgence à ${cityName} ? Faites confiance à MesBonsPros par HomeServe et son réseau de 4 000 professionnels partout en France pour vous venir en aide rapidement.`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
            break;
        case 3:
            meta = (
                <Helmet>
                    <title>Petits travaux et dépannages d'urgence à {cityName} - MesBonsPros</title>
                    <meta
                        name="description"
                        content={`Vous êtes à la recherche d'un plombier, un électricien ou tout autre dépanneur à ${cityName} ? Découvrez MesBonsPros par HomeServe pour une intervention rapide et de qualité à votre domicile.`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
            break;
        case 4:
            meta = (
                <Helmet>
                    <title>Réparations et travaux de la maison à {cityName} - MesBonsPros</title>
                    <meta
                        name="description"
                        content={`Besoin d'une réparation en plomberie, serrurerie ou électricité à ${cityName} ? Faites appel au réseau de 4 000 professionnels proposé par MesBonsPros par HomeServe disponible dans toute la France !`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
            break;
        default:
            meta = (
                <Helmet>
                    <title>Petits travaux et dépannages à domicile à {cityName} - MesBonsPros</title>
                    <meta
                        name="description"
                        content={`Vous vivez à ${cityName} et avez un problème de plomberie, électricité, serrurerie ou électroménager ? Faites confiance à MesBonsPros par HomeServe pour un dépannage à domicile à ${cityName} dans les meilleurs délais.`}
                        data-react-helmet="true"
                    />
                    <link rel="canonical" href={pageUrl} />
                </Helmet>
            );
    }
    return <>{meta}</>;
}
