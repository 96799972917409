import React from "react";
import styled from "styled-components";
import IconFacebook from "../../assets/svg/facebook.svg";
import IconTwitter from "../../assets/svg/twitter.svg";
import IconInstagramm from "../../assets/svg/instagramm.svg";
import IconLinkedin from "../../assets/svg/linkedin.svg";
import IconYoutube from "../../assets/svg/youtube.svg";

const StyledSocial = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-around;
`;

const StyledSocialLink = styled.a`
  svg {
    path {
      fill: white;
    }
  }


  &:hover {
    cursor: pointer;
  }
`;

const socialData = [
  {
    id: 1,
    network: "facebook",
    url: "https://www.facebook.com/MesBonsPros",
  },
  {
    id: 2,
    network: "instagramm",
    url: "https://www.instagram.com/mesbonspros/",
  },
  {
    id: 3,
    network: "linkedin",
    url: "https://www.linkedin.com/company/homeserve-france/",
  },
  {
    id: 4,
    network: "youtube",
    url: "https://www.youtube.com/channel/UCHP22m2OFXz22InvuQ5t0Qw",
  },
];

export function SocialNetworks() {
  function Social({ network }) {
    switch (network) {
      case "facebook":
        return <IconFacebook />;
      case "linkedin":
        return <IconLinkedin />;
      case "twitter":
        return <IconTwitter />;
      case "youtube":
        return <IconYoutube />;
      case "instagramm":
        return <IconInstagramm />;
      default:
        return null;
    }
  }
  return (
    <StyledSocial>
      {socialData.map((social) => {
        return (
          <StyledSocialLink
            key={social.id}
            href={social.url}
            target="_blank"
            rel="noreferrer"
            aria-label={social.network}
          >
            <Social network={social.network} />
          </StyledSocialLink>
        );
      })}
    </StyledSocial>
  );
}
