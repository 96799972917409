import React from "react";
import { Link } from "gatsby";
import useResponsive from "../../hooks/use-responsive";
import styled from "styled-components";
import { DrawerWrapper, Wrapper } from "../index";
import Logo from "../../assets/svg/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import breakpoint from "../../theme/breakpoints";
import { useDrawerContext } from "../../context/drawer-context";
import { CgClose } from "react-icons/cg";
import * as website from "../../../config/website";

const StyledHeader = styled.header`
  height: 100px;
  width: 100%;
  margin: 0 0 1px 0;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 9px solid ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.primary};

  @media only screen and ${breakpoint.device.sm} {
    position: fixed;
    top: 0;
    height: 60px;
    border-bottom: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%);
  }

  @media only screen and ${breakpoint.device.xs} {
    position: fixed;
    top: 0;
    height: 60px;
    border-bottom: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%);
  }
`;

const StyledHeaderWrapper = styled(Wrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const StyledLink = styled.li`
  padding: 0 0.8rem;
  position: relative;
  display: inline-block;

  a {
    font-size: 14px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  &:last-child {
    padding-right: 0;
    a {
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      background-color: ${(props) => props.theme.colors.secondary};
      text-transform: none;
    }
  }
`;

const navData = [
  {
    id: 1,
    label: "Plomberie",
    url: "/autodiagnostic/plomberie",
  },
  {
    id: 2,
    label: "Électricité",
    url: "/autodiagnostic/electricite",
  },
  {
    id: 3,
    label: "Gaz & Fioul",
    url: "/autodiagnostic/gaz",
  },
/*  {
    id: 4,
    label: "Serrurerie",
    url: "/autodiagnostic/serrurerie",
  }, */
  {
    id: 5,
    label: "Électroménager",
    url: "/autodiagnostic/electromenager",
  },
  {
    id: 6,
    label: "Nuisibles",
    url: "/autodiagnostic/nuisibles",
  },
  {
    id: 7,
    label: "Suivre votre commande",
    url: "/suivi",
  },
];

export function Header() {
  const { isBigScreen } = useResponsive();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  return (
    <>
      {isBigScreen && (
        <StyledHeader id="header">
          <StyledHeaderWrapper>
            <div>
              <a href={website.url}>
                <Logo />
              </a>
            </div>
            <nav>
              <ul>
                {navData.map((nav) => {
                  return (
                    <StyledLink key={nav.id}>
                      <a href={nav.url}>{nav.label}</a>
                    </StyledLink>
                  );
                })}
              </ul>
            </nav>
          </StyledHeaderWrapper>
        </StyledHeader>
      )}
      {!isBigScreen && (
        <>
          <StyledHeader
            id="header"
            style={{ position: "fixed", height: "62px", zIndex: "999" }}
          >
            <StyledHeaderWrapper>
              <div>
                <a href={website.url}>
                  <Logo />
                </a>
              </div>
              <div>
                <div
                  color="red"
                  onClick={() => toggleIsOpened(!isOpened)}
                  // className={classes.icon}
                >
                  {isOpened ? <CgClose /> : <GiHamburgerMenu />}
                </div>
              </div>
            </StyledHeaderWrapper>
          </StyledHeader>
          <DrawerWrapper navData={navData} />
        </>
      )}
    </>
  );
}
