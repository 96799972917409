import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1184px;
  height: auto;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
`;

export function Wrapper({ children, className }) {
  return <StyledWrapper className={className}>{children}</StyledWrapper>;
}
