import React from "react";
import styled from "styled-components";
import { Wrapper } from "../commons/wrapper";
import { SocialNetworks } from "../index";
import { MdBuild } from "react-icons/md";
import breakpoint from "../../theme/breakpoints";
import useResponsive from "../../hooks/use-responsive";

const StyledFooter = styled.footer`
  height: auto;
  width: 100%;
  padding: 1rem 0;
  margin: 0 0 1px 0;
  box-sizing: border-box;
  background-color: black;
  color: white;

  a {
    font-size: 14px;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledFooterWrapper = styled(Wrapper)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: column;
  }
  @media only screen and ${breakpoint.device.xs} {
    flex-direction: column;
  }
`;

const StyledLegals = styled.div`
  width: 100%;
  padding: 0.6rem 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.customGrayDark};
  text-align: center;

  a {
    padding-right: 1rem;
    margin-right: 0.6rem;
    color: ${(props) => props.theme.colors.customGrayDark};
    position: relative;

    &:after {
      content: "•";
      position: absolute;
      right: 0;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
`;

const StyledBlockPhone = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  color: black;

  a {
    color: black;
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-size: 1rem;
    margin-top: 0.2rem;
    padding: 0.8rem 2rem 0.6rem;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-radius: 32px;
    transition: all 0.2s;

    @media only screen and ${breakpoint.device.sm} {
      font-size: 0.9rem;
    }
    @media only screen and ${breakpoint.device.xs} {
      font-size: 0.9rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
      color: white;
      text-decoration: none;

      .tool {
        color: white;
      }
    }

    .tool {
      font-size: 1.8rem;
      color: ${(props) => props.theme.colors.secondary};
      vertical-align: middle;
    }
  }
`;

const StyledBlockFlex = styled.div`
  margin: 0.2rem;
  display: flex;
  align-content: center;
  align-items: center;

  div {
    width: 50%;
    height: 62px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid black;

    &.phone-number {
      font-size: 1.4rem;
      font-weight: bold;

      @media only screen and ${breakpoint.device.sm} {
        font-size: 0.9rem;
      }
      @media only screen and ${breakpoint.device.xs} {
        font-size: 0.9rem;
      }
    }

    small {
      font-size: 12px;
    }

    &:last-child {
      font-size: 12px;
      color: white;
      background-color: black;
      position: relative;

      &:before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        background-color: white;
        position: absolute;
        top: 38%;
        left: -12px;
        transform: rotate(45deg);
      }
    }
  }
`;

const StyledAddress = styled.div`
  line-height: 1.6rem;

  @media only screen and ${breakpoint.device.sm} {
    text-align: center;
  }
  @media only screen and ${breakpoint.device.xs} {
    text-align: center;
  }
`;

const StyledNav = styled.div`
  ul {
    display: block;
    width: fit-content;
  }
  @media only screen and ${breakpoint.device.sm} {
    text-align: center;
    margin: 0 auto;
    ul {
      text-align: left;
    }
  }
  @media only screen and ${breakpoint.device.xs} {
    text-align: center;
    margin: 0 auto;
    ul {
      text-align: left;
    }
  }
`;

const navData = [
  {
    id: 1,
    label: "Dépannage plomberie",
    url: "/autodiagnostic/plomberie",
  },
  {
    id: 2,
    label: "Dépannage électricité",
    url: "/autodiagnostic/electricite",
  },
  {
    id: 3,
    label: "Dépannage gaz",
    url: "/autodiagnostic/gaz",
  },
/*  {
    id: 4,
    label: "Dépannage serrurerie",
    url: "/autodiagnostic/serrurerie",
  },*/
  {
    id: 5,
    label: "Dépannage électroménager",
    url: "/autodiagnostic/electromenager",
  },
  {
    id: 6,
    label: "Nos tarifs",
    url: "/nos-tarifs",
  },
  {
    id: 7,
    label: "Besoin d'aide",
    url: "/aide",
  },
  {
    id: 8,
    label: "Nos professionnels près de chez vous",
    url: "/depannage-domicile/",
  },
];

const legalData = [
  {
    id: 1,
    label: "Mentions légales",
    url: "/mentions-legales",
  },
  {
    id: 2,
    label: "Condition Générales de vente",
    url: "/conditions-generales-de-vente",
  },
  {
    id: 3,
    label: "Condition Générales d'utilisation",
    url: "/conditions-generales-d'utilisation",
  },
  {
    id: 4,
    label: "Politique des cookies",
    url: "/politique-des-cookies",
  },
  {
    id: 5,
    label: "Politique de confidentialité",
    url: "/politique de confidentialite",
  },
  {
    id: 6,
    label: "Gestion des cookies",
    url: "/didomi",
  },
];

export function Footer() {
  const { isBigScreen } = useResponsive();
  return (
    <StyledFooter id="footer">
      <StyledFooterWrapper>
        <div>
          <StyledBlockPhone>
            Vous êtes un <strong>particulier</strong>
            <StyledBlockFlex>
              <div className="phone-number">01 70 82 17 82</div>
              <div>Service gratuit + coût d'un appel</div>
            </StyledBlockFlex>
            <small>du lundi au dimanche de 07h00 à 21h00</small>
          </StyledBlockPhone>
          <StyledBlockPhone>
            Vous êtes un <strong>professionnel</strong>
            <a href="tel:0170821782">
              <MdBuild className="tool" /> Rejoindre notre réseau
            </a>
          </StyledBlockPhone>
        </div>
        <StyledNav>
          <h3>MesBonsPros</h3>
          <ul>
            {navData.map((nav) => {
              return (
                <li key={nav.id}>
                  <a href={nav.url}>{nav.label}</a>
                </li>
              );
            })}
          </ul>
        </StyledNav>
        {isBigScreen && (
          <StyledAddress>
            <div>
              MesBonsPros par HomeServe
              <br />9 rue Anna Marly, 69007 Lyon
              <SocialNetworks />
            </div>
          </StyledAddress>
        )}
      </StyledFooterWrapper>
      {!isBigScreen && (
        <StyledFooterWrapper>
          <StyledAddress style={{ margin: "0 auto" }}>
            <div>
              MesBonsPros par HomeServe
              <br />9 rue Anna Marly, 69007 Lyon
              <SocialNetworks />
            </div>
          </StyledAddress>
        </StyledFooterWrapper>
      )}
      <StyledFooterWrapper>
        <StyledLegals>
          {legalData.map((legal) => {
            return (
              <a key={legal.id} href={legal.url}>
                {legal.label}
              </a>
            );
          })}
        </StyledLegals>
      </StyledFooterWrapper>
      <StyledFooterWrapper>
        <StyledLegals>
          © HomeServe {new Date().getFullYear()}
        </StyledLegals>
      </StyledFooterWrapper>
      <StyledFooterWrapper>
        <StyledLegals>
          <small>
            (1) Données HomeServe basées sur les interventions déployées entre
            avril 2019 et mars 2020. (2) Temps moyen de réponse constaté en
            2019.
          </small>
        </StyledLegals>
      </StyledFooterWrapper>
    </StyledFooter>
  );
}
