import React, { useContext, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { AppContext } from "../../context/app-context";
import breakpoint from "../../theme/breakpoints";
import ArrowLink from "../../assets/svg/arrow_link.svg";
import Phone from "../../assets/svg/phone";
const StyledLink = styled.a`
  width: 100%;
  max-width: 456px;
  height: 100%;
  display: block;
  margin: 10px 0;
  padding: 0.8rem 0.4rem;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  color: black;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;

  svg {
    vertical-align: middle;
    fill: ${(props) => props.theme.colors.hsRed};
    margin-left: 0.6rem;
  }

  span {
    font-weight: bold;
  }

  &.red {
    padding: 0.8rem 1rem;
    background-color: ${(props) => props.theme.colors.hsRed};
    color: white;
    font-size: 1.5rem;

    svg {
      fill: white;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.darkenHsRed};
    }
  }
  &.disabled {
    padding: 0.8rem 1rem;
    background-color: ${(props) => props.theme.colors.customGrayLight};
    color: black;
    font-size: 1.5rem;
    cursor: default;
  }

  @media only screen and ${breakpoint.device.xs} {
    max-width: 90%;
  }
`;

const StyledArrow = styled(ArrowLink)`
  width: 24px;
  height: 17px;
  color: white;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: 456,
    maxWidth: "100%",
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    // padding: 20,
    background: "white",
  }),

  valueContainer: () => ({
    padding: "1rem 0 0.6rem 1rem",
    height: "27px",
    borderColor: "white",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#16445f", // Custom colour
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "white",
  }),
};

export function FormHero({ options, defaultValue }) {
  const { state } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [phoneNumber] = useState(state.phoneNumber);
  const url = phoneNumber.replace(/ /g, "");
  return (
    <div>
      <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        isSearchable={false}
        placeholder="Plomberie, Électricité, Gaz... ?"
        styles={customStyles}
        menuPlacement="auto"
      />
      <div>
        {selectedOption === "" ? (
          <StyledLink className="disabled" href="/">
            {/*<ArrowLink />*/}
            C'est parti!
          </StyledLink>
        ) : (
          <StyledLink
            className="red"
            href={`/autodiagnostic/${selectedOption.value}`}
          >
            <StyledArrow />
            C'est parti !
          </StyledLink>
        )}
      </div>
      <div>
        <StyledLink href={`tel:${url}`}>
          Ou contactez nous au <span>{phoneNumber}</span> <Phone />
        </StyledLink>
      </div>
    </div>
  );
}
