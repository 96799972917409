import React from "react";
import styled from "styled-components";
import { Wrapper } from "../index";
import breakpoint from "../../theme/breakpoints";

const StyledTextImg = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 2rem 0;
  display: flex;
  align-items: center;
`;

const StyledContentText = styled.div`
  width: 100%;
  max-width: 567px;
  display: block;
  line-height: 24px;

  p {
    padding-top: 0;
    margin-top: 0;
  }

  @media only screen and ${breakpoint.device.sm} {
    max-width: 100%;
  }
`;

const StyledContentImg = styled.div`
  width: 100%;
  max-width: 100%;
  text-align: right;

  img {
    width: 100%;
    max-width: 455px;
    height: auto;

    @media only screen and ${breakpoint.device.sm} {
      width: 100%;
      height: auto;
      text-align: center;
    }
    @media only screen and ${breakpoint.device.xs} {
      width: 100%;
      height: auto;
    }
  }
  @media only screen and ${breakpoint.device.sm} {
    text-align: center;
  }
`;

const WrapperFlex = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  padding: 0!important;

  @media only screen and ${breakpoint.device.xs} {
    flex-direction: column;
  }
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: column;
  }
`;

const StyledTitle = styled.div`
  margin-top: 0;
  font-size: 32px;
  color: black;

  h1,
  h2 {
    font-size: 32px;
    color: black;
  }
`;

export function TextImg({ title, text, image }) {
  return (
    <StyledTextImg>
      <Wrapper>
        <StyledTitle>{title}</StyledTitle>
        <WrapperFlex>
          <StyledContentText dangerouslySetInnerHTML={{ __html: text }} />
          <StyledContentImg>
            <img src={image} alt="" />
          </StyledContentImg>
        </WrapperFlex>
      </Wrapper>
    </StyledTextImg>
  );
}
