import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Wrapper } from "../index";
import { MdPhone } from "react-icons/md";
import { AppContext } from "../../context/app-context";
import useResponsive from "../../hooks/use-responsive";
import breakpoint from "../../theme/breakpoints";

const StyledHeader = styled.div`
  height: 2.5rem;
  width: 100%;
  margin: 0 0 1px 0;
  box-sizing: border-box;
  background-color: black;
  color: white;

  a {
    color: white;
    text-decoration: none;
  }

  svg {
    vertical-align: middle;
  }
  @media only screen and ${breakpoint.device.sm} {
    margin: 62px 0 0 0;
  }

  @media only screen and ${breakpoint.device.xs} {
    margin: 62px 0 0 0;
  }
`;

const StyledHeaderWrapper = styled(Wrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  @media only screen and ${breakpoint.device.sm} {
    padding: 0;
  }

  @media only screen and ${breakpoint.device.xs} {
    padding: 0;
  }
`;

const StyledTopNav = styled.nav`
  font-size: 0.9rem;
`;

export function HeaderTop() {
  const { isBigScreen } = useResponsive();
  const { state } = useContext(AppContext);
  const [phoneNumber] = useState(state.phoneNumber);
  const url = phoneNumber.replace(/ /g, "");
  return (
    <StyledHeader>
      <StyledHeaderWrapper>
        <div>
          <MdPhone style={{ fontSize: "1.4rem", marginLeft: "1.4rem" }} />
          <a href={`tel:${url}`}>
            <span>{phoneNumber}</span>
            {"  "}
            <span style={{ fontSize: "0.8rem", fontWeight: "normal" }}>
              Service gratuit + coût de l'appel
            </span>
          </a>
        </div>
        {isBigScreen && (
          <StyledTopNav>
            <a
              target="_blank"
              href="https://www.homeserve.fr/"
              rel="noreferrer"
            >
              HomeServe.fr
            </a>
            <span style={{ padding: "0 0.8rem" }}>|</span>
            <a href="https://depannage.homeserve.fr/qui-sommes-nous">
              Qui sommes-nous ?
            </a>
            <span style={{ padding: "0 0.8rem" }}>|</span>
            <a href="https://depannage.homeserve.fr/aide">Aide</a>
          </StyledTopNav>
        )}
      </StyledHeaderWrapper>
    </StyledHeader>
  );
}
