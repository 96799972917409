import { darken } from "polished";

const theme = {
  colors: {
    text: "#333333",
    primary: "#16445f",
    secondary: "#34e2d9",
    hsRed: "#ff5a5a",
    darkenHsRed: darken(0.1, "#ff5a5a"),
    customRed: "#e7342c",
    customGrayLight: "#e0e0e0",
    customGray: "#d1d7db",
    customGrayDark: "#999999",
  },
  fonts: ["sans-serif", "MuseoSansRounded"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};
export default theme;
