import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "../../theme";
import { Footer, Header, HeaderTop } from "../index";
import "../../fonts.css";
import useResponsive from "../../hooks/use-responsive";

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    font-size: 16px;
    font-family: "MuseoSansRounded";
  }
`;

export function Layout({ children }) {
  const { isBigScreen } = useResponsive();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>
        {isBigScreen && <HeaderTop />}
        <Header />
        {!isBigScreen && <HeaderTop />}
        {children}
        <Footer />
      </main>
    </ThemeProvider>
  );
}
