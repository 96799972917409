import React from "react";
import styled from "styled-components";
import { FormHero, Title, Wrapper } from "../index";
import HeroImg from "../../assets/domain/hero.png";
import useResponsive from "../../hooks/use-responsive";
import breakpoint from "../../theme/breakpoints";

const StyledHero = styled.div`
  width: 100%;
  min-height: 430px;
  margin: 0;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.secondary};

  // @media only screen and ${breakpoint.device.sm} {
  //   margin-top: 60px;
  // }

  // @media only screen and ${breakpoint.device.xs} {
  //   margin-top: 60px;
  // }
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  height: fit-content;
  align-items: center;
  //padding: 0;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.secondary};

  @media only screen and ${breakpoint.device.sm} {
    padding: 0 24px;
    text-align: center;
  }

  @media only screen and ${breakpoint.device.xs} {
    padding: 0 24px;
    text-align: center;
  }
`;

const StyledFormContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;

  .form-wrapper {
    max-width: 560px;
    @media only screen and ${breakpoint.device.sm} {
      max-width: 100%;
    }
  }
  .form-box {
    max-width: 456px;
    @media only screen and ${breakpoint.device.sm} {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const StyledImgContainer = styled.div`
  width: 100%;
  min-height: 430px;
  position: relative;

  img {
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledTitle = styled(Title)`
  font-size: 32px;
  color: black;
  h1,
  h2,
  div {
    font-size: 32px;
    color: black;
  }
`;

export function Hero({ title, children }) {
  const { isBigScreen } = useResponsive();
  return (
    <StyledHero>
      <StyledWrapper>
        <StyledFormContainer>
          <div className="form-wrapper">
            <StyledTitle>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </StyledTitle>
            <div className="form-box">{children}</div>
          </div>
        </StyledFormContainer>
        {isBigScreen && (
          <StyledImgContainer>
            <img width="auto" height="400" src={HeroImg} alt="" />
          </StyledImgContainer>
        )}
      </StyledWrapper>
    </StyledHero>
  );
}
