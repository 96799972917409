import React from "react";
import styled from "styled-components";
import { Wrapper, Title } from "../index";

const StyledText = styled.div`
  width: 100%;
  height: auto; //remove
  margin: 0 0 2rem 0;
  display: block;
  align-items: center;

  p {
    line-height: 24px;
  }
`;

export function Text({ title, text }) {
  return (
    <StyledText>
      <Title>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </Title>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </StyledText>
  );
}
